<template>
    <div class="mask" v-if="detailFlag">
        <div class="slider">
            <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
            <div class="title">客户资料
                <!-- <img class="title_edit" src="@/assets/imgs/edit.png" alt="" @click="edit">  -->
                <img class="title_close" src="@/assets/imgs/close.png" alt="" @click="hideMask">
                <a-icon type="edit" @click="edit" v-if="flag == false"/>
                &nbsp;&nbsp;<a-icon type="save" @click="save"  v-if="flag == true"/>
            </div>
            <div class="content" style="height:185px" v-if="portraitList.corp">
                <div v-for="(item,index) in portraitList.corp.slice(0,3)" :key="index">
                    <template v-if="item.value != null && item.value != 0">
                        <div class="content_row content_detail">
                            <label class="info" for="">{{item.text}}:</label>
                            <div style="width:calc(100% - 110px)">
                                <template v-if="item.type == 'date' || item.type == 'time' || item.type == 'dateTime' || item.type == 'city'">
                                    <span>{{item.value2}}</span>
                                </template>
                                <template v-else-if="item.type == 'radioTag'">
                                    <span class="radioTag">{{item.value}}</span>
                                </template>
                                <template v-else-if="item.type == 'checkTag'">
                                    <span v-for="item2 in item.value" :key="item2" class="radioTag">{{item2}}</span>
                                </template>
                                <template v-else-if="item.type == 'selects'">
                                    <span v-for="item2 in item.value" :key="item2" class="radioTag">{{item2}}</span>
                                </template>
                                <template v-else-if="item.type == 'annex'">
                                    <!-- <a :href="'/scrm/index/Download?id=' + item.value">{{item.value}}</a> -->
                                </template>
                                <template v-else>
                                    <span>{{item.value}}</span>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="content_row content_detail">
                    <label class="info" for="">联系人:</label>
                    <div>
                        <div style="cursor:pointer;margin-bottom:15px;" v-for="(item2,index2) in portraitList.linknam" :key="index2" @click="linkmanDetail(item2.tmjcpb_linkman__lid)">
                            {{item2.tmjcpb_linkman__name}}   {{item2.tmjcpb_linkman__mobile}}
                        </div>
                        <div><a-icon type="user-add" @click="addLinkman(id)"/></div>
                    </div>
                </div>
            </div>

            <!-- 五个菜单切换 -->
            <a-card class="clientDetail" style="width:100%;border: 0;" :tab-list="tabListNoTitle" :active-tab-key="noTitleKey" @tabChange="key => onTabChange(key, 'noTitleKey')">
                <p v-if="noTitleKey === 'portrait'">
                    <template>
                        <div class="content" style="padding:0;" v-if="flag == false">
                            <div v-for="(item,index) in portraitList.corp" :key="index">
                                <template v-if="item.value != null && item.value != 0">
                                    <div class="content_row content_detail">
                                        <label class="info" for="">{{item.text}}:</label>
                                        <div style="width:calc(100% - 110px)">
                                            <template v-if="item.type == 'date' || item.type == 'time' || item.type == 'dateTime' || item.type == 'city'">
                                                <span>{{item.value2}}</span>
                                            </template>
                                            <template v-else-if="item.type == 'radioTag'">
                                                <span class="radioTag">{{item.value}}</span>
                                            </template>
                                            <template v-else-if="item.type == 'checkTag'">
                                                <span v-for="item2 in item.value" :key="item2" class="radioTag">{{item2}}</span>
                                            </template>
                                            <template v-else-if="item.type == 'selects'">
                                                <span v-for="item2 in item.value" :key="item2" class="radioTag">{{item2}}</span>
                                            </template>
                                            <template v-else-if="item.type == 'annex'">
                                                <a :href="url + '/scrm/index/Download?id=' + item.value">{{item.value}}</a>
                                            </template>
                                            <template v-else-if="item.type == 'image'">
                                                <img :src="url + '/scrm/index/getFile?id=' + item.value">
                                            </template>
                                            <template v-else>
                                                <span>{{item.value}}</span>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="content" style="padding:0;" v-else>
                            <div v-for="(item,index) in portraitList.corp" :key="index">
                                <template v-if="item.iswrite == 1">
                                    <div class="content_row content_detail">
                                        <label class="info" for="" :style="flag == true ? 'line-height:32px': ''">{{item.text}}:</label>
                                        <!-- 数字 -->
                                        <div class="content_detail_right" v-if="item.type == 'number'">
                                            <a-input type="number" v-model="item.value"></a-input>
                                        </div>
                                        <!-- 单行文本 -->
                                        <div class="content_detail_right" v-if="item.type == 'SingleText'">
                                            <a-input v-model="item.value"></a-input>
                                        </div>
                                        <!-- 多行文本 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'multiText'">
                                            <a-textarea v-model="item.value" :auto-size="{ minRows: 5, maxRows: 8 }">{{item.value}}</a-textarea>
                                        </div>
                                        <!-- 单选标签 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'radioTag'">
                                            <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                                            <!-- <a-radio-group v-model="item.value">
                                                <a-radio class="radioTag" v-for="(option,index2) in item.options" :key="index2" :value="option.value">
                                                    {{option.value}}
                                                </a-radio>
                                            </a-radio-group> -->
                                            <a-radio-group v-model="item.value" button-style="solid">
                                                <a-radio-button class="radioTag" v-for="(option,index2) in item.options" :key="index2" :value="option.value">
                                                    {{option.value}}
                                                </a-radio-button>
                                            </a-radio-group>
                                        </div>  
                                        <!-- 多选标签 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'checkTag'">
                                            <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                                            <a-checkbox-group v-model="item.value" :options="item.options"></a-checkbox-group>
                                        </div>
                                        <!-- 时间 -->
                                        <!-- <div class="content_detail_right" v-else-if="item.type == 'time'">
                                            <a-time-picker v-model="item.value" /> 
                                        </div> -->
                                        <!-- 日期 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'date'">
                                            <a-date-picker v-model="item.value" /> 
                                        </div>
                                        <!-- 日期时间 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'dateTime'">
                                            <a-date-picker show-time v-model="item.value" />
                                        </div>
                                        <!-- 下拉框 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'select'">
                                            <a-select size="large" v-model="item.value"> 
                                                <a-select-option  v-for="(option,index2) in item.options" :key="index2" :value="option.value">{{option.value}}</a-select-option>
                                            </a-select>
                                        </div>
                                        <!-- 下拉框 多选-->
                                        <div class="content_detail_right" v-else-if="item.type == 'selects'">
                                            <a-select size="large" mode="multiple" v-model="item.value"> 
                                                <a-select-option v-for="(option,index2) in item.options" :key="index2" :value="option.value">{{option.value}}</a-select-option>
                                            </a-select>
                                        </div>
                                        <!-- 附件-->
                                        <div class="content_detail_right" v-else-if="item.type == 'annex'">
                                            <a :href="url + '/scrm/index/Download?id='+ item.value" v-if="item.value">{{item.value2}}</a>
                                            <label class="fileLabel" for="annex"><a-icon type="upload" /> 上传 </label>
                                            <input type="file" id="annex" value="" @change="annexUpload($event,index)">
                                        </div>
                                        <!-- 单张图 -->
                                        <div class="content_detail_right" v-else-if="item.type == 'image'">
                                            <img v-if="item.value" :src="url + '/scrm/index/getFile?id=' + item.value">
                                            <label class="fileLabel" for="image"><a-icon type="upload" /> 上传 </label>
                                            <input type="file" id="image" accept="image/*" value="" @change="imgUpload($event,index)">
                                        </div>
                                        <!-- 多张图-->
                                        <div class="content_detail_right" v-else-if="item.type == 'images'">
                                            <img v-if="item.value" :src="url + '/scrm/index/getFile?id=' + item.value">

                                            <label class="fileLabel" for="images"><a-icon type="upload" /> 上传 </label>
                                            <input type="file" id="images" accept="image/*" value="" @change="imgsUpload($event,index)">

                                            <!-- <a-upload list-type="picture-card" :file-list="fileList" :customRequest="customRequest" @change="handleChange">
                                                <div v-if="fileList.length < 8">
                                                    <a-icon type="plus" />
                                                    <div class="ant-upload-text">Upload</div>
                                                </div>
                                            </a-upload> -->
                                        </div>
                                        <!-- 地区-->
                                        <div class="content_detail_right" v-else-if="item.type == 'city'">
                                            <a-cascader placeholder="请选择地区" :options="citys" v-model="item.value"/>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </p>
                <p v-else-if="noTitleKey === 'journey'" class="jour">
                    <a-button type="primary" @click="showJourney" style="margin-bottom:20px;">新建跟进记录</a-button>
                    <a-timeline>
                        <div v-for="(item,index) in jourList" :key="index">
                            <a-timeline-item>
                                <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                                <span class="jourDate">{{item.created}}</span>
                            </a-timeline-item>
                            <a-timeline-item>
                                <div class="jourContent" :style="item.isme == 1 ? 'background-color: #E7F5FF' : 'background-color: #F6F6F6'">
                                    <div class="flex jourContentTop">
                                        <div class="flex jourContentTopLeft">
                                            <span><b>{{item.name}}</b></span> 
                                            <a-tag :color="item.typeColor">{{item.type}}</a-tag>
                                        </div>
                                        {{item.created2}}
                                    </div>
                                    <div class="jourContentBot" style="width:100%;">
                                        <template v-if="item.content_type == 1">
                                            {{item.content}}
                                        </template>
                                        <template v-else-if="item.content_type == 2">
                                            <audio controls :src="url + '/scrm/index/getFile?id='+ item.content" style="height:30px"></audio>
                                        </template>
                                        <template v-else-if="item.content_type == 3">
                                            <img :src="url + '/scrm/index/getFile?id='+ item.content" alt="">
                                        </template>
                                    </div>
                                </div>
                            </a-timeline-item>
                        </div>
                    </a-timeline>
                </p>
                <p v-if="noTitleKey === 'business'">
                    <a-button type="primary" @click="showChance">新建商机</a-button>
                    <a-list :data-source="businessList">
                        <a-list-item slot="renderItem" slot-scope="item,index" >
                            <div class="chance">
                                <div v-if="item.status == 0">
                                    <div style="line-height:40px"><b>商机状态:</b>已放弃</div>
                                    <div style="line-height:40px"><b>放弃原因:</b>{{item.reason}}</div>
                                    <div style="line-height:40px"><b>放弃商机备注:</b>{{item.reason1}}</div>
                                    <div style="line-height:40px"><b>商机标题:</b>{{item.title}}</div>
                                    <div style="line-height:40px"><b>商机内容:</b>{{item.content}}</div>
                                </div>
                                <div v-else>
                                    <div class="flex" style="width:350px;flex-wrap:wrap">
                                        <div class="chanceStage" v-for="(item2,index2) in chanceArr" :key="index2">
                                            <a-button :class="[item2.name == item.status ?'stageActive':'']" @click="setStatus(item2.name,index,item.id)">{{item2.value}}</a-button>
                                            <a-icon v-if="index2 != chanceArr.length-1" type="caret-right" />
                                        </div>
                                    </div>
                                    <div style="line-height:40px"><b>商机标题:</b>{{item.title}}</div>
                                    <div style="line-height:40px"><b>商机内容:</b>{{item.content}}</div>
                                    <div v-for="(item2,index2) in businessList[index].result" :key="index2">
                                        <p style="margin-bottom:5px"><a href="javascript:;">{{item2.file_name}}</a></p>
                                    </div>
                                    <div style="margin-bottom:10px">
                                        <label class="fileLabel" for="quotation" style="width:auto;margin-right:10px !important;"><a-icon type="upload" /> 上传报价方案</label>
                                        <input type="file" id="quotation" value="" @change="replyChance($event,item.id,1,index)">

                                        <label class="fileLabel" for="contract" style="width:auto;"><a-icon type="upload" /> 上传合同</label>
                                        <input type="file" id="contract" value="" @change="replyChance($event,item.id,2,index)">
                                    </div>
                                </div>
               
                                <div>
                                    <a class="action" href="javascript:;" v-if="item.status != 0" @click="editChance(item.id,item.title,item.content)">编辑</a>
                                    <a class="action del" href="javascript:;"  v-if="item.status != 0" @click="closeChance(item.id)">放弃商机</a>
                                    <a-popconfirm title="确定要删除吗?" @confirm="() => delChance(item.id)">
                                        <a class="action del" href="javascript:;">删除</a>
                                    </a-popconfirm>
                                </div>
                            </div>
                        </a-list-item>
                    </a-list>
                </p>
                <p v-else-if="noTitleKey === 'task'">
                    <a-button type="primary" @click="addTask">新建任务</a-button>
                    <a-list :data-source="taskList">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <div class="w100">
                                <div style="line-height:30px;"><b>任务描述:</b>{{item.task_content}}</div>
                                <div class="flex commonContent">
                                    <div><b>开始时间:</b>{{item.start}}</div>
                                    <div><b>创建人:</b>{{item.creater_name}}</div>
                                </div>
                                <div v-if="item.status != 3">
                                    <a class="action" href="javascript:;" @click="finishTask(item.id)">完成</a>
                                </div>
                            </div>
                        </a-list-item>
                    </a-list>
                </p>
                <p v-else-if="noTitleKey === 'order'">
                    <a-button type="primary" @click="addOrder">新建合同</a-button>
                    <a-list :data-source="orderList">
                        <a-list-item slot="renderItem" slot-scope="item" >
                            <div class="w100">
                                <div class="flex commonContent">
                                    <div><b>合同编号:</b>{{item.osn}}</div>
                                </div>
                                <div class="flex commonContent">
                                    <div><b>合同状态:</b>{{item.status}}</div>
                                    <div><b>跟进人:</b>{{item.staff_uid}}</div>
                                </div>
                                <div class="flex commonContent">
                                    <div><b>签订时间:</b>{{item.sign_time}}</div>
                                </div>
                            </div>
                        </a-list-item>
                    </a-list>
                </p>
            </a-card>
        </div>
        <a-modal v-model="journeyVisible" title="新建跟进记录" @ok="journeyOk">
            <!-- <div class="content_row flex">
                <label class="info" for="">跟进类型：</label>
                <a-radio-group v-model="journeyType">
                    <a-radio v-for="(item,index) in journeyArr" :key="index" :value="item.value">
                        {{item.label}}
                    </a-radio>
                </a-radio-group>
            </div> -->
            <div class="content_row flex">
                <label class="info" for="">内容类型</label>
                <a-radio-group v-model="journeyContentType">
                    <a-radio v-for="(item,index) in journeyContentArr" :key="index" :value="item.value">
                        {{item.label}}
                    </a-radio>
                </a-radio-group>
            </div>
            <div class="content_row flex" v-if="linkmanList.length != 0">
                <label class="info" for="">联系人</label>
                <a-select size="large" v-model="lid"> 
                    <a-select-option v-for="item in linkmanList" :key="item.lid" :value="item.lid">{{item.name}}</a-select-option>
                </a-select>
            </div>
            <div class="content_row" :class="[journeyContentType == 1 ? '' : 'flex']">
                <label class="info" for="">跟进内容</label>
                <template v-if="journeyContentType == 1">
                    <a-textarea style="width:80%;margin:10px 0;" :auto-size="{ minRows: 5, maxRows: 8 }" v-model="journeyInput" placeholder="请输入内容"></a-textarea>
                </template>
                <template v-else-if="journeyContentType == 2">
                    <div>
                        <audio controls v-if="fileId != null && fileId != 0" :src="url + '/scrm/index/getFile?id='+ fileId"></audio>
                        <label class="fileLabel" for="journeyAudio"><a-icon type="upload" /> 上传 </label>
                        <input type="file" id="journeyAudio" value="" @change="journeyAudioUpload($event)">
                    </div>
                </template>
                <template v-else-if="journeyContentType == 3">
                    <div>
                        <div v-if="fileId != null && fileId != 0"><img :src="url + '/scrm/index/getFile?id='+ fileId" alt="" style="max-width:300px;margin-bottom:10px"></div>
                   
                        <label class="fileLabel" for="journeyImage"><a-icon type="upload" /> 上传 </label>
                        <input type="file" id="journeyImage" accept="image/*" value="" @change="journeyImgUpload($event)">
                    </div>
                </template>
            </div>
        </a-modal>
        <a-modal v-model="chanceVisible" title="新建商机" @ok="chanceOk">
            <div class="content_row flex">
                <label class="info" for="">商机标题</label>
                <a-input v-model="chanceTitle" placeholder="请输入商机标题"/>
            </div>
            <div class="content_row">
                <label class="info" for="">商机内容</label>
                <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="chanceInput" placeholder="请输入商机内容"></a-textarea>
            </div>
        </a-modal>
        <a-modal v-model="chanceVisible2" title="放弃商机" @ok="chanceOk2">
            <div class="content_row flex">
                <label class="info" for="">输单类型</label>
                <a-select v-model="loseType" placeholder="请选择输单类型">
                    <a-select-option v-for="item in loseList" :key="item.name" :value="item.value">
						{{item.value}}
					</a-select-option>
                </a-select>
            </div>
            <div class="content_row">
                <label class="info" for="">输单内容</label>
                <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="loseInput" placeholder="请输入输单内容"></a-textarea>
            </div>
        </a-modal>
    </div>
</template>
<script>
import {requestXml,getDateTime,fileUpload,ajaxUrl} from '../assets/js/request';
import citys from '@/assets/js/city.js';
const wx = window.wx;
var moment = require('moment');
// import $ from "jquery"
import "../assets/css/client.css"
export default {
    props: {
        detailFlag: {
            type: Boolean,
            default: false
        },
        clientId: {
            type: Number
        },
        clientName:{
            type: String
        },
    },   
    data() {
        return {
            fileList: [],
            citys,
            url: ajaxUrl,
            // 是否编辑
            flag: false,

            tabListNoTitle: [
                {key: 'portrait',tab: '画像',},
                {key: 'journey',tab: '旅程',},
                {key: 'business',tab: '商机',},
                {key: 'task',tab: '任务',},
                {key: 'order',tab: '合同',},
            ],
            key: 'portrait',
            noTitleKey: 'portrait',
            //客户id,name
            id: 0,
            name: "",

            // all,
            portraitList: [],
            jourList: [],
            businessList: [],
            taskList: [],
            orderList: [],

            // 联系人
            linkmanList: [],
            lid: null,
            // 跟进记录添加框
            journeyVisible: false,
            journeyInput: "",
            journeyType: 3,
            journeyArr: [
                {"value": 1,"label": "动态","typeColor": "blue"},
                {"value": 2,"label": "资料变更","typeColor": "red"},
                {"value": 3,"label": "跟进","typeColor": "purple"},
                {"value": 4,"label": "任务","typeColor": "orange"},
                {"value": 5,"label": "商机","typeColor": "green"}
            ],
            journeyContentType: 1,
            journeyContentArr: [
                {"value": 1,"label": "文本"},
                // {"value": 2,"label": "语音"},
                {"value": 3,"label": "图片"}
            ],
            // 跟进记录文件id
            fileId: null,
            // 商机添加框
            chanceArr: [],
            chanceVisible: false,
            chanceTitle: "",
            chanceInput: "",
            
            chanceVisible2: false,
            loseType: "",
            loseList: [],
            loseInput: "",
            // 商机id
            cid: "",
            chanceFiles: [],
            // data: [],
            // loading: false,
            // busy: false,
            orderStatus: ["取消","新建","进行中","已完成"],

            appId: "",
            timestamp: "",
            nonceStr: "",
            signature: "",
            agentid: "",
        };
    },
    beforeMount() {
        
    },
    watch: { 
        clientId: function(newVal){ 
            if(newVal){
                this.id = newVal;
                this.getPortrait();
                // this.noTitleKey = "portrait";
                // 设置为查看
                this.flag = false;
            }
        },
        clientName: function(newVal){ 
            if(newVal){
                this.name = newVal;
            }
        } 
    },
    computed: {
     
    },
    mounted: function(){
        for(let i=0;i<citys.length;i++){
            citys[i].value = citys[i].label
            for(let j=0;j<citys[i].children.length;j++){
                citys[i].children[j].value = citys[i].children[j].label
                for(let k=0;k<citys[i].children[j].children.length;k++){
                    citys[i].children[j].children[k].value = citys[i].children[j].children[k].label
                }
            }
        }
        // this.getAll();
        // this.getConfig((res) => {
        //     if(res == "success"){
        //         wx.invoke('getCurExternalContact', {}, function(res){
        //             console.log(res,666)
        //             if(res.err_msg == "getCurExternalContact:ok"){
        //                 alert(res.userId,777)
        //                 console.log(res.userId,123456); //返回当前外部联系人userId
        //             }else {
        //                 //错误处理
        //             }
        //         });
        //     }
        // });
    },
    methods: {
        // handleChange({ file,fileList }) {
        //     console.log(fileList,file)
        //     this.fileList = fileList.filter(item => item.status !== 'error');
        // },
        // customRequest (data) {
        //     const formData = new FormData()
        //     formData.append('file', data.file)
        //     console.log(data.file)
        //     // console.log(formData)
        //     this.saveFile(formData)
        // },
        // saveFile (formData) {
        //     // this.form.validateFields((err, ) => {
        //     // if (!err) {
                
        //         $.ajax({
        //             url: ajaxUrl + "/scrm/index/UpFiles",
        //             type: 'POST',
        //             data: formData,
        //             processData: false, // 使数据不做处理
        //             contentType: false, // 不要设置Content-Type请求头
        //             success: (res) => {
        //                 console.log(res,8888)
        //             },
        //             error: () => {

        //             }
        //         })
        //         // }
        //     // })
        // },
        // asn,fileId,type
        getConfig(callback){
            requestXml("/scrm/Index/getJsSdkConfig","POST",(res) => {
                this.appId = res.corpid; // 必填，企业微信的corpID
                this.timestamp= res.timestamp; // 必填，生成签名的时间戳
                this.nonceStr = res.nonceStr; // 必填，生成签名的随机串
                this.signature = res.signature;
                this.agentid = res.agentid;
          
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: this.appId, // 必填，企业微信的corpID
                    timestamp: this.timestamp, // 必填，生成签名的时间戳
                    nonceStr: this.nonceStr, // 必填，生成签名的随机串
                    signature: this.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                    jsApiList: [""], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                });
                wx.ready((res)=>{
                    console.log("wx.ready",res,666)
                    // console.log(this.appId,this.timestamp,this.nonceStr,this.signature,this.agentid)
                    wx.agentConfig({
                        corpid: this.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: this.agentid, // 必填，企业微信的应用id （e.g. 1000247）
                        timestamp: this.timestamp, // 必填，生成签名的时间戳
                        nonceStr: this.nonceStr, // 必填，生成签名的随机串
                        signature: this.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: ['thirdPartyOpenPage','getCurExternalContact'], //必填，传入需要使用的接口名称
                        success:(res)=>{
                            console.log(res)
                            callback("success");
                        },
                        fail: (res)=> {
                            if(res.errMsg.indexOf('function not exist') > -1){
                                alert('版本过低请升级')
                            }
                        }
                    });
                });
                wx.error((res)=>{
                    console.log("wx.error",res,999)
                });
            },{"url": location.href.split('#')[0]}) //location.href.split('#')[0]
        },
        addLinkman(id){
            this.$emit('updateDetailFlag', false);
            this.$emit('updateAddFlag2', true);
            // 添加联系人传客户id
            this.$emit('clientIdFunc', id);
        },
        linkmanDetail(id){
            this.$emit('updateDetailFlag', false);
            this.$emit('updateAddFlag2', true);
            // 编辑联系人传联系人id
            this.$emit('linkmanIdFunc', id);
        },
        edit(){
            this.flag = true;
            this.noTitleKey = 'portrait';
        },
        hideMask(){
            this.id = "";
            this.flag = false;
            this.$emit('updateDetailFlag', false);
        },
        // 编辑客户
        save(){
            this.flag = false;
            let corp = this.portraitList.corp;
            let corpObj = {};
            for(let i=0;i<corp.length;i++){
                // console.log(corp[i].type,corp[i].value,corp[i].value && corp[i].iswrite == 1)
                if(corp[i].value && corp[i].iswrite == 1){
                    if(corp[i].type == 'date' || corp[i].type == 'dateTime'  || corp[i].type == 'time'){
                        corp[i].value2 = corp[i].value._d;
                        corp[i].value = parseInt(corp[i].value._d.getTime()/1000);
                    }else if(corp[i].type == 'selects' && corp[i].value.length != 0){
                        corp[i].value = corp[i].value.join(",");
                    }else if(corp[i].type == 'checkTag' && corp[i].value.length != 0){
                        corp[i].value = corp[i].value.join(",");
                    }else if(corp[i].type == 'city' && corp[i].value.length != 0){
                        // console.log(corp[i].value,"corp[i].value")
                        corp[i].value = corp[i].value.join(",");
                    }
                    corpObj[corp[i].name] = corp[i].value;
                }
            }
            corpObj["ids"] = this.id;
            // console.log(corpObj,"corpObj")
            requestXml("/scrm/corp/up","POST",(res) => {
                if(res == "success"){
                    this.getPortrait();
                    this.flag = false;
                    // 更新客户列表数据
                    this.$emit('updateList', true);
                }
            },corpObj)
        },
        // 详情下的切换
        onTabChange(key, type) {
            this[type] = key;
            if(this[type] == "portrait" ){
                this.getPortrait();
            }else if(this[type] == "journey" ){
                this.getJour();
            }else if(this[type] == "business" ){
                this.getBusiness();
                this.getStage();
            }else if(this[type] == "task" ){
                this.getTask();
            }else if(this[type] == "order" ){
                this.getOrder();
            }
        },
        // 获取画像
        getPortrait(){
            requestXml("/scrm/Corp/getCorpDetails","GET",(res) => {
                // console.log(res)
                for(let i=0;i<res.corp.length;i++){
                    this.id = res.ids;
                    if(res.corp[i].value != null && res.corp[i].value != "" && res.corp[i].value != 0 && res.corp[i].value.length != 0){
                        if(res.corp[i].type == 'date'){
                            res.corp[i].value2 = getDateTime(res.corp[i].value,1);
                            res.corp[i].value = new moment(getDateTime(res.corp[i].value,1));
                        }else if(res.corp[i].type == 'dateTime'){
                            res.corp[i].value2 = getDateTime(res.corp[i].value,5);
                            res.corp[i].value = new moment(getDateTime(res.corp[i].value,5))
                        }else if(res.corp[i].type == 'city'){
                            res.corp[i].value2 = res.corp[i].value;
                            res.corp[i].value = res.corp[i].value.split(",");
                        }
                    }
                    if(res.corp[i].type == 'checkTag' || res.corp[i].type == 'selects' || res.corp[i].type == 'images'){
                        // console.log(res.corp[i].options.length)
                        if(res.corp[i].options){
                            for(let j=0;j<res.corp[i].options.length;j++){
                                res.corp[i].options[j].label = res.corp[i].options[j].value;
                            }
                        }
                    } 
                }
                this.portraitList = res;
                // console.log(this.portraitList,"this.portraitList")
            },{"ids": this.id})
        },
        // 获取旅程
        getJour(){
            requestXml("/scrm/Follow/getCorpDetails","GET",(res) => {
                for(let i=0;i<res.length;i++){
                    if(res[i].content_type != 1){
                        res[i].content = Number(res[i].content);
                    }
                    res[i].typeColor = this.journeyArr[Number(res[i].type) - 1].typeColor;
                    res[i].type = this.journeyArr[Number(res[i].type) - 1].label;
                    
                    // 时分
                    res[i].created2 = getDateTime(res[i].created,2);
                    // 年月日
                    res[i].created = getDateTime(res[i].created);
                }
                this.jourList = res;
            },{"ids": this.id})
        },
        getStage(){
            requestXml("/scrm/Setting/getSetting","GET",(res) => {
                this.chanceArr = res.section;
            })
        },
        setStatus(status,idx,id){
            // console.log(status,idx)
            this.businessList[idx].status = status;
            requestXml("/scrm/Chance/setStatus","POST",(res) => {
                console.log(res)
            },{"id": id,"status":status})
        },
        // 获取商机
        getBusiness(){
            requestXml("/scrm/Chance/getCorpDetails","GET",(res) => {
                this.businessList = res;
            },{"ids": this.id})
        },
        // 获取客户任务
        getTask() {
            requestXml("/scrm/Task/getCorpDetails","GET",(res) => {
                for(let i=0;i<res.length;i++){
                    res[i].start = getDateTime(res[i].start);
                }
                this.taskList = res;
            },{"ids": this.id})
           
        },
        // 获取客户合同
        getOrder(){
            requestXml("/scrm/Order/getCorpDetails","GET",(res) => {
                for(let i=0;i<res.length;i++){
                    res[i].status = this.orderStatus[res[i].status];
                    res[i].sign_time = getDateTime(res[i].sign_time);
                }
                this.orderList = res;
            },{"ids": this.id})
           
        },
        showJourney(){
            this.journeyContentType = 1;
            this.lid = null;
            this._content = "";
            this.journeyVisible = true;
            requestXml("/scrm/linkman/getMinList","GET",(res) => {
                this.linkmanList = res;
                if(res.length != 0){
                    this.lid = res[0].lid;
                }
                
            },{"ids": this.id})
        },
        journeyOk(){
            if(this.journeyInput == "" && this.journeyContentType == 1){
                this.$message.info('输入的内容不能为空');
                return false;
            }else{
                this.journeyVisible = false;
                this.addJourney();
            }
        },
        // 添加跟进记录
        addJourney(){
            let _content = "";
            if(this.journeyContentType == 1){
                _content = this.journeyInput;
            }else{
                _content = this.fileId;
            }
            requestXml("/scrm/Follow/add","POST",(res) => {
                console.log(res)
                this.getJour();
            },{"lid": this.lid,ids: this.id,"type": this.journeyType,"content_type": this.journeyContentType,"content": _content})
        },

        showChance(){
            this.cid = "";
            this.chanceVisible = true;
        },
        chanceOk(){
            if(this.chanceTitle == ""){
                this.$message.info('输入的标题不能为空');
                return false;
            }else if(this.chanceInput == ""){
                this.$message.info('输入的内容不能为空');
                return false;
            }else{
                this.chanceVisible = false;
                this.addChance();
            }
        },
        // 添加商机
        addChance(){
            let url = "";
            if(this.cid){
                url = "/scrm/Chance/up";
            }else{
                url = "/scrm/Chance/add";
            }
            requestXml(url,"POST",(res) => {
                if(res == "success"){
                    this.chanceInput ="";
                    this.chanceTitle ="";
                    this.getBusiness();
                }
            },{"id": this.cid,"content": this.chanceInput,"title": this.chanceTitle,"ids": this.id,"lid":""})
        },
        // 编辑商机
        editChance(id,title,content){
            this.cid = id;
            this.chanceVisible = true;
            this.chanceTitle = title;
            this.chanceInput = content;
        },
        
        // 放弃商机
        closeChance(id){
            this.cid = id;
            this.loseType = "";
            this.loseInput = "";
            this.chanceVisible2 = true;
            requestXml("/scrm/setting/getSetting","GET",(res) => {
                this.loseList = res.give_up;
            })
        },
        chanceOk2(){
            if(this.loseType == ""){
                this.$message.info('请选择输单类型');
                return false;
            }else if(this.loseInput == ""){
                this.$message.info('请输入输单内容');
                return false;
            }else{
                this.chanceVisible2 = false;
                requestXml("/scrm/Chance/setClose","POST",(res) => {
                    console.log(res)
                    this.getBusiness();
                },{"id": this.cid,"reason": this.loseType,"doc": this.loseInput})
            }
        },
        // 删除商机
        delChance(id){
            requestXml("/scrm/Chance/del","POST",(res) => {
                if(res == "success"){
                    this.getBusiness();
                }
            },{"id": id})
        },
        approve(asn,fileId,type){
            let templateId = "";
            // 1报价方案
            if(type == "quotation"){
                templateId = "27d35bc380a9b63357c8c5822b2bed62_1859344462";
            }else{
                templateId = "5c8ab9f1291bd6f9ec199c8a51864ed1_1044199122";
            }
            // 回调
            // 改价:        a012ab752540df9a97710ac73e0b87ef_64102059
            // 报价方案:    27d35bc380a9b63357c8c5822b2bed62_1859344462
            // 合同:        5c8ab9f1291bd6f9ec199c8a51864ed1_1044199122
            wx.invoke('thirdPartyOpenPage', {
                "oaType": "10001",// String
                "templateId": templateId,// String
                "thirdNo": asn,// String
                "extData": {
                    'fieldList': [{
                        'title': '文件审批',
                        'type': 'text',
                        'value': '合同审批',
                    },
                    {
                        'title': '文件链接',
                        'type': 'link',
                        'value': 'https://scrm.jctmj.cn/index.php/scrm/index/Download?id='+ fileId,
                    }],
                }
            },function(res) {
                // 输出接口的回调信息
                console.log(res);
            });
        },
        replyChance(e,id,type,idx){
            if(type == 1){
                type = "quotation";
            }else{
                type = "contract";
            }
            fileUpload(e,(res) => {
                console.log(idx)
                let obj = {};
                obj.file = res.name;
                // obj.id = res.id;
                this.businessList[idx].result.push(obj)
                requestXml("/scrm/Chance/setResult","POST",(res) => {
                    console.log(res)
                    // if(res.asn){
                    //     this.approve(res.asn,res.file,type);
                    // }
                },{"cid": id,"type": type,"file": res.id})
            })
        },
        addTask(){
            this.$router.push({
                path: "/scrm_pc/task/taskAdd",
                query: {
                    client: this.id,
                    clientName: this.name,
                    detail: "pc"
                }
            });
        },
        // 完成
        finishTask(id){
            requestXml("/scrm/Task/setDone","POST",(res) => {
                console.log(res)
                this.getTask();
            },{"id": id})
        },
        addOrder(){
            this.$router.push({
                path: "/scrm_pc/order/orderAdd",
                query: {
                    client: this.id,
                    clientName: this.name,
                    detail: "opc"
                }
            });
        },
        // 旅程图片上传
        journeyImgUpload(e){
            fileUpload(e,(res) => {
                console.log(res)
                this.fileId = res.id;
            })
        },
        journeyAudioUpload(e){
            fileUpload(e,(res) => {
                console.log(res)
                this.fileId = res.id;
            })
        },
        // 附件上传
        annexUpload(e,index){
            fileUpload(e,(res) => {
                this.portraitList.corp[index].value = res.id;
                this.portraitList.corp[index].value2 = res.name;
               
            })
        },
        // 图片上传
        imgUpload(e,index){
            fileUpload(e,(res) => {
                this.portraitList.corp[index].value = res.id;
            })
        },
        // 多图上传
        imgsUpload(e,index){
            fileUpload(e,(res) => {
                this.portraitList.corp[index].value = res.id;
            })
        },
    },
    // filters:{
    //     dataFiltter(val){
    //         for(var i=0;i<all.length;i++){
    //             if(val == all[i].name){
    //                 return all[i].text + ":" 
    //             }
    //         } 
    //     }
    // }
};
</script>
<style scoped>
    
</style>
